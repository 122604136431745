/* eslint-disable @typescript-eslint/naming-convention */
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconClock from "../../public/svg/icon-clock.svg";
import IconEvent from "../../public/svg/icon-event.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import { CalendarPlaces, CalendarRowWithDateTime } from "../types/calendar";

export const TEST_ID_OPENING_HOURS_LIST_ITEM = "opening-hours-list-item";

interface OpeningHoursListProps {
  today: DateTime;
  calendarRow?: CalendarRowWithDateTime;
}

const calendarTitleByKey: Record<keyof CalendarPlaces, string> = {
  Huvipuisto: "openingHours.amusementPark",
  Nukkekoti: "openingHours.dollhouse",
  "Koiramäen eläinpuisto": "openingHours.doghill",
  "Koiramäen K18 Kesäillat": "openingHours.doghillSummerNights",
  "Retro – Särkänniemi Arcade": "openingHours.retroSarkanniemiArcade",
  Akvaario: "openingHours.aquarium",
  Planetaario: "openingHours.planetarium",
  "Ursan tähtinäytös": "openingHours.ursaStarShow",
  "Näsinneulan Näkötorni": "openingHours.nasinneulaTower",
  "Ravintola Näsinneula": "openingHours.nasinneulaRestaurant",
  "Neula Sky Cafe": "openingHours.neulaSkyCafe",
  "Ravintola ILO": "openingHours.restaurantILO",
  "Sara Hildénin taidemuseo": "openingHours.saraHilden",
};

const OpeningHoursList: React.FC<OpeningHoursListProps> = ({
  today,
  calendarRow,
}) => {
  const { locale } = useIntl();
  const { styles } = useContext(ThemeContext);

  const visiblePlaces = useMemo(
    () =>
      calendarRow !== undefined
        ? Object.entries(calendarTitleByKey).filter(
            ([key]) => calendarRow[key as keyof CalendarPlaces] !== undefined,
          )
        : [],
    [calendarRow],
  );

  if (!calendarRow) {
    return (
      <div className={styles.paragraph3}>
        <FormattedMessage id="openingHours.notFoundForCurrentDate" />
      </div>
    );
  }

  const { Tiedote, Tiedote_ENG, Tiedoteteksti, Tiedoteteksti_ENG, Pvm } =
    calendarRow;

  let specialEventTitle;
  let specialEventContent;

  const translateOpeningHour = (openingHour: string): string | JSX.Element => {
    if (openingHour === "Avoin") {
      return <FormattedMessage id="openingHours.open" />;
    } else if (openingHour === "Suljettu") {
      return <FormattedMessage id="openingHours.closed" />;
    } else {
      return openingHour;
    }
  };

  if (Tiedote && Tiedote_ENG) {
    if (locale === "fi") {
      specialEventTitle = Tiedote;
      specialEventContent = Tiedoteteksti;
    } else {
      specialEventTitle = Tiedote_ENG;
      specialEventContent = Tiedoteteksti_ENG;
    }
  }

  return (
    <div>
      <h2
        className={classNames(styles.heading2, "mt0 mb4 flex items-center")}
        data-testid="opening-hours-list-title"
      >
        <span className="fill-transparent stroke-current-color f0 mr2">
          <IconClock />
        </span>
        <span className="mt1">
          {today.equals(Pvm) ? (
            <FormattedMessage id="openingHours.today" />
          ) : (
            Pvm.setLocale(locale).toLocaleString()
          )}
        </span>
      </h2>
      <ul className={classNames(styles.paragraph3, "list ma0 pa0")}>
        {visiblePlaces.map(([key, translationId]) => {
          const value = calendarRow[key as keyof CalendarPlaces];
          if (value === undefined) {
            return null;
          }
          return (
            <li
              key={key}
              className={classNames(
                styles.paragraph3,
                "flex justify-between mb3",
              )}
              data-testid={TEST_ID_OPENING_HOURS_LIST_ITEM}
            >
              <span>
                <FormattedMessage id={translationId} />
              </span>
              <span>{translateOpeningHour(value)}</span>
            </li>
          );
        })}
      </ul>
      {specialEventTitle && (
        <div className="mt4 mt5-l">
          <h2
            className={classNames(styles.heading2, "mt0 mb4 flex items-center")}
          >
            <span className="f0 mr2">
              <IconEvent />
            </span>
            <span className="mt1">
              <FormattedMessage id="openingHours.specialEvents" />
            </span>
          </h2>
          <ul className={classNames(styles.paragraph3, "list ma0 pa0")}>
            <li className="flex">
              <span
                className="bg-pink br-100 dib flex-shrink-0 mt1"
                aria-hidden="true"
                style={{ width: "0.5rem", height: "0.5rem" }}
              ></span>
              <span className="ml2">
                {`${specialEventTitle}${
                  specialEventContent ? ` / ${specialEventContent}` : ""
                }`}
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default OpeningHoursList;
